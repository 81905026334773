import request from "@/utils/request";

// 公告列表
export function getNoticeList(gameId,query) {
  return request({
    url: '/function/notice/list/'+gameId,
    headers: {
      isToken: false,
	  isMallApi: true
    },
    method: 'get',
	params: query
  })
}
// 公告详情
export function getNoticeDetail(gameId,id) {
  return request({
    url: '/function/notice/selectOne/'+gameId+'/'+id,
    headers: {
      isToken: false,
	  isMallApi: true
    },
    method: 'get'
  })
}
// 配置详情
export function getConfig(gameId,pid) {
  return request({
    url: '/function/url/selectUrlByGameIdAndPlatform/'+gameId+'/'+pid,
    headers: {
      isToken: false,
      isMallApi: true
    },
    method: 'get'
  })
}
<template>
  <section class="bannerBox" v-if="isConfig && isConfig.gameId == gameId"
  :style="'background-image: url('+bannerBg+');'" 
  ></section>
  
  <!-- 开始游戏 -->
  <section class="startBox" v-if="isConfig && isConfig.gameId == gameId">
  	  <div class="startBtn" @click="openGame" @mouseover="changeActiveBg" @mousemove="changeNormalBg">
		  <img v-if="isHover" :src="startBgStyle.activeImage" />
		  <img v-else :src="startBgStyle.normalImage" />
		  <img class="recom" src="~@/assets/images/web/recommend.png" />
  	  </div>
  </section>
  
  <section class="bg1" :style="'background-image: url('+topBg+');'" v-if="isConfig && isConfig.gameId == gameId">
	  <div class="listBox">
		  <div class="downloadBox" @click="toGMStore">
			  <!-- <ul>
				  <li>
					  <a target="_blank" :href="urls.googlePlayUrl">
						<img src="~@/assets/images/web/btn_main_google.png" />
					  </a>
				  </li>
				  <li>
					  <a target="_blank" :href="urls.oneStoreUrl">
						<img src="~@/assets/images/web/btn_main_onestore.png" />
					  </a>
				  </li>
				  <li>
					  <a target="_blank" :href="urls.galaxyStoreUrl">
						<img src="~@/assets/images/web/btn_main_galaxy.png" />
					  </a>
				  </li>
				  <li>
					  <a target="_blank" :href="urls.googlePlayUrl">
						<img src="~@/assets/images/web/btn_main_google.png" />
					  </a>
				  </li>
			  </ul> -->
		  </div>
		  <swiper
		    :effect="'coverflow'"
			:autoplay="{
			  delay: 3000,
			  stopOnLastSlide: false,
			  disableOnInteraction: false,
			}"
			:grabCursor="true"
			:centeredSlides="true"
			:slidesPerView="'auto'"
			:coverflowEffect="{
			  rotate: 50,
			  stretch: 0,
			  depth: 100,
			  modifier: 1,
			  slideShadows: isConfig.fivepic == 1 ? true : false,
			}"
			:pagination="true" 
		  	:style="{
		  	  '--swiper-pagination-color': '#fff'
		  	}"
		    class="mySwiper"
		    >
		    <swiper-slide v-for="(item,index) in adList" :key="index">
		  		<div class="ad">
		  			<img :src="item.url">
		  		</div>
		  	</swiper-slide>
		  </swiper>
		  <div class="tabsList">
			  <van-tabs v-model:active="activeTab" class="newsTabs" @click-tab="handleTabsClick" 
				  background="transparent" 
				  color="transparent" 
				  shrink
			  >
				  <!-- 公告 -->
			      <!-- <el-tab-pane label="공지" name="9"> -->
			      <van-tab title="공지" name="9" :title-style="newsTabbar">
					  <div class="listCon">
						  <ul>
							  <li v-for="(item,index) in noticeList" :key="index">
								  <router-link target="_blank" :to="'/news/'+item.id">[공지] {{ item.title }}</router-link>
								  <span>[{{ parseTime(item.updateTime,'{m}-{d}') }}]</span>
							  </li>
						  </ul>
					  </div>
				  </van-tab>
				  <!-- 活动 -->
			      <!-- <el-tab-pane label="이벤트" name="6"> -->
			      <van-tab title="이벤트" name="6" :title-style="newsTabbar">
					  <div class="listCon">
						  <ul>
							  <li v-for="(item,index) in noticeList" :key="index">
								  <router-link target="_blank" :to="'/news/'+item.id">[이벤트] {{ item.title }}</router-link>
								  <span>[{{ parseTime(item.updateTime,'{m}-{d}') }}]</span>
							  </li>
						  </ul>
					  </div>
				  </van-tab>
				  <!-- 攻略 -->
			      <!-- <el-tab-pane label="공략" name="18"> -->
			      <van-tab title="공략" name="18" :title-style="newsTabbar">
					  <div class="listCon">
						  <ul>
							  <li v-for="(item,index) in noticeList" :key="index">
								  <router-link target="_blank" :to="'/news/'+item.id">[공략] {{ item.title }}</router-link>
								  <span>[{{ parseTime(item.updateTime,'{m}-{d}') }}]</span>
							  </li>
						  </ul>
					  </div>
				  </van-tab>
				  
				</van-tabs>
				<div class="moreBtn"><router-link target="_blank" to="/home/news">더보기</router-link></div>
		  </div>
	  </div>
  </section>
  
<component v-if="gameComponent.isShow" :is="gameComponent.content"></component>

<!-- 福利介绍 -->
<section class="imgBgBox" v-if="isConfig && isConfig.gameId == gameId">
	  <img :src="welfareBg" />
</section>

<!-- 五图 isConfig.fivepic=1是竖的五图 -->
<section class="bgimg" :style="'background-image: url('+fivepicBg+');'" v-if="gameInfo.gameFiveCharts">
	  <div class="imgBox" :class="{'Portrait': isConfig.fivepic == 1}">
		  <swiper
		      :effect="'coverflow'"
			  :autoplay="{
				  delay: 3000,
				  stopOnLastSlide: false,
				  disableOnInteraction: false,
			  }"
		      :grabCursor="true"
		      :centeredSlides="true"
		      :slidesPerView="'auto'"
		      :coverflowEffect="{
		        rotate: 50,
		        stretch: 0,
		        depth: 100,
		        modifier: 1,
		        slideShadows: true,
		      }"
		      :pagination="true"
		      :style="{
		        '--swiper-pagination-color': '#fff'
		      }"
		      class="picSwiper"
		    >
		      <swiper-slide v-for="(item,index) in formatImgs(gameInfo.gameFiveCharts)" :key="index">
				  <img :src="item" />
			  </swiper-slide>
		    </swiper>
	  </div>
</section>
	<Login :showLogin="openLogin" :gameid="gameInfo.gameId" @loginSuccess="loginSuccess" />
	<div class="maxBox" :class="!isMobile ? 'pcbox' : ''" v-if="!isConfig.gameId">
		<h1>Loading...</h1>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, reactive, onMounted, defineAsyncComponent, shallowRef, shallowReactive, computed, watch, getCurrentInstance } from "vue";
import { getNoticeList, getConfig } from '@/api/web'
import { getSearch } from '@/api/index'
import useConfigStore from '@/store/modules/config'
import useUserStore from '@/store/modules/user'
import Login from '@/components/login'

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Autoplay,EffectCoverflow,Pagination,Navigation } from 'swiper/core';
import "swiper/components/effect-coverflow";
SwiperCore.use([Autoplay,EffectCoverflow,Pagination,Navigation]);

let router = useRouter();
const loading = ref(false)
const platformId = useUserStore().platformId
// 9:公告，6:活动，18:攻略
const activeTab = ref('9')
const noticeList = ref([])
let queryParams = reactive({
	pageNum: 1,
	pageSize: 10
})
let urls = reactive({
	galaxyStoreUrl: '',
	googlePlayUrl: '',
	oneStoreUrl: '',
	neverUrl: ''
})
const openLogin = ref(false)
const gameId = ref()
const gameInfo = ref({})
const adList = ref([])
const bannerBg = ref(null)
const topBg = ref(null)
const welfareBg = ref(null)
const fivepicBg = ref(null)
const isConfig = ref({})//是否配置当前游戏
let newsTabbar = reactive({
	backgroundImage: 'none'
})
let startBgStyle = reactive({
	activeImage: null,
	normalImage: null
})
let gameComponent = shallowReactive({
	isShow: false,
	content: null
})
const userToken = computed(() => {
	return useUserStore().userToken || ''
})
const isMobile = computed(() => {
	return useConfigStore().isMobile
})
const isHover = ref(false)
const formatImgs = (str) => {
	let arr = []
	if(!str){
		return arr
	}
	if(str && str.indexOf(',') > 0){
		arr = str.split(',')
	}else if(str.indexOf(',') == -1){
		arr = [str]
	}
	return arr
}
const openGame = () => {
	if(userToken.value){
		router.push({
		  path:'/gameplay/'+gameInfo.value.gameId
		});
	}else{
		openLogin.value = true
		console.log('打开盒子登录弹窗')
	}
}
const toGMStore = () =>{
	window.open('https://www.gm321321.com/home/recommend','_blank')
}

const changeActiveBg = () => {
	isHover.value = true
}
const changeNormalBg = () => {
	isHover.value = false
}

const handleTabsClick = ({ name }) => {
	activeTab.value = name
	if(activeTab.value != 0){
		getList()
	}
}
const handleNewsDetail = (row) => {
	router.push({
	  path:'/news/'+row.id
	});
}
const loginSuccess = (data) => {
	openLogin.value = false
	openGame()
}
onMounted(()=>{
	const gameConfigs = require('@/assets/images/web/config.json')
	const query = router.currentRoute.value.params
	gameId.value = query.id
	getSearch(query).then((response)=>{
		gameInfo.value = response.data || {};
		let isconfig = gameConfigs.filter((item) => item.gameId == gameInfo.value.gameId)
		if(isconfig.length > 0){
			isConfig.value = isconfig[0]
			
		}else{
			router.push({ path:'/' });
		}
	}).catch((err) => {
		console.log(err);
		router.push({ path:'/' });
	})
	
	getList()
	getConfig(gameId.value,platformId).then((res) => {
		urls.galaxyStoreUrl = res.data.galaxyStoreUrl
		urls.googlePlayUrl = res.data.googlePlayUrl
		urls.oneStoreUrl = res.data.oneStoreUrl
		urls.neverUrl = res.data.guoneiUrl
	})
	
})
const getList = () => {
	loading.value = true
	getNoticeList(gameId.value,{ noticeType: activeTab.value,...queryParams }).then((res) => {
		noticeList.value = res.rows
		loading.value = false
	}).catch(() => {
		loading.value = false
	})
}

watch(isConfig, (newVal, oldVal) => {
	if(newVal){
		adList.value = [
			{ url: require('@/assets/images/web/'+gameId.value+'/ad1.jpg') },
			{ url: require('@/assets/images/web/'+gameId.value+'/ad2.jpg') },
			{ url: require('@/assets/images/web/'+gameId.value+'/ad3.jpg') },
			{ url: require('@/assets/images/web/'+gameId.value+'/ad4.jpg') },
			{ url: require('@/assets/images/web/'+gameId.value+'/ad5.jpg') }
		]
		bannerBg.value = require('@/assets/images/web/'+gameId.value+'/banner.jpg')
		topBg.value = require('@/assets/images/web/'+gameId.value+'/bg_top.jpg')
		welfareBg.value = require('@/assets/images/web/'+gameId.value+'/bg_welfare.jpg')
		fivepicBg.value = require('@/assets/images/web/'+gameId.value+'/bg_five.jpg')
		newsTabbar.backgroundImage = 'url('+require('@/assets/images/web/'+gameId.value+'/news_on.png')+')'
		startBgStyle.activeImage = require('@/assets/images/web/'+gameId.value+'/start_game_on.jpg')
		startBgStyle.normalImage = require('@/assets/images/web/'+gameId.value+'/start_game.jpg')
		if(newVal.otherComponent && newVal.otherComponent === '816'){
			gameComponent.isShow = true
			gameComponent.content = defineAsyncComponent(() => import('./webother/816.vue'));
		}
	}
})
</script>
<style lang="less">
  @import '@/assets/styles/common';
</style>
<style lang="less" scoped>
section{
	min-width: 1280px;
}
.bannerBox{
	width: 100%;height: calc(100vw * 816 / 1920 - 4px);overflow: hidden;
	min-width: 1280px;min-height: 540px;
	background-repeat: no-repeat;background-position: center;
	background-size: 100%;position: relative;z-index: 1;
}
.bg1{
	width: 100%;height: calc(100vw * 324 / 1920 - 4px);min-height: 214px;
	background-repeat: no-repeat;background-position: center;
	background-size: 100%;
}
.imgBgBox{
	font-size: 0;
	img{
		width: 100%;height: calc(100vw * 700 / 1920 - 4px);min-height: 466.27px;
	}
}
.bgimg{
	background-repeat: no-repeat;background-position: center;
	background-size: 100%;
	.imgBox{
		width: 100%;height: calc(100vw * 717 / 1920 - 4px);min-height: 474px;
		padding: 8% 0 0!important;box-sizing: border-box;
		&.Portrait{//竖版
			padding: 9% 0 0!important;
			.picSwiper{
				width: 50%;
				.swiper-slide{
					width: calc(100vw * 717 / 1920 * 0.9 * 503 / 896 - 10px);
					 img{
						 width: auto;height: 100%;
					 }
				}
			}
			 
		}
	}
}

.startBox{
	width: 100%;height: calc(100vw * 120 / 1920 - 4px);min-height: 76px;
	// overflow: hidden;
	.startBtn{
		width: 100%;height: 100%;transition: 0.3s;
		background-size: 100%;
		position: relative;
		&:hover{
			cursor: pointer;
			background-size: 100%;
		}
		img{
			width: 100%;
		}
		.recom{
			width: 20%;
			position: absolute;
			z-index: 1;
			top: -45%;
			right: 18%;
		}
	}
}
.listBox{
	display: flex;align-items: flex-start;justify-content: center;
	height: 100%;overflow: hidden;padding: 0 0 2%!important;
	width: 63%;margin: 0 auto;
	.downloadBox{
		cursor: pointer;
		width: 25%;height: 90%;
		background: url('~@/assets/images/web/gm_store.png') no-repeat center;
		background-size: 100%;
		padding: 0 5px;overflow-y: scroll;box-sizing: border-box;
		ul{
			width: 100%;
			li{
				width: 100%;font-size: 0;margin: 4px 0 1px;
				img{
					width: 100%;cursor: pointer;
				}
			}
		}
	}
	.tabsList{
		flex: 1;padding: 0 16px 16px;background: transparent;
		height: 90%;overflow: hidden;box-sizing: border-box;position: relative;
		.moreBtn{
			position: absolute;top:13px;right: 16px;font-size: 12px;
			a{
				color: #FEF9F1;
				&:hover{
					color: #EC821B;cursor: pointer;
				}
			}
		}
		/deep/ .van-tabs--line .van-tabs__wrap{
			height: 40px;border-bottom: 1px solid #fff;
		}
		/deep/ .van-tab{
			color: #6F6666;transition: 0.3s;font-size: 0.875rem;
			background-size: 0;background-repeat: no-repeat;
			&.is-active,&:hover{
				color: #fff;
				padding: 0 20px;
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: right center;
			}
		}
		/deep/ .van-tab--active{
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: right center;
			color: #fff;
			padding: 0 20px;
		}
		.newsTabs{
			
			.listCon{
				height: 225px;overflow-y: scroll;color: #FEF9F1;
				
				ul{
					li{
						width: 100%;line-height: 20px;border-bottom: 1px dashed #FFFCF0;
						display: flex;padding: 8px 0;color: #FEF9F1;font-size: 14px;
						a{
							display: inline-block;flex: 1;color: #FEF9F1;
							&:hover{
								color: #EC821B;cursor: pointer;
							}
						}
					}
				}
			}
			
		}
	}
}

.mySwiper{
	width: 46%;height: 90%;
	.ad{
		position: relative;width: 100%;height: 100%;overflow: hidden;
		img{
			height: 100%;position: absolute;
			top: 2%;left: 50%;transform: translate(-50%,0);
		}
	}
}
.picSwiper{
	width: 56%;height: 88%;
	text-align: center;
	.swiper-slide {
	  background-position: center;
	  background-size: cover;
	  width: 80%;
	  height: auto;
	  img{
	  	width: 100%;
	  }
	  .imgX{
		  width: 40%;height: 100%;
	  }
	}
	
}

</style>
